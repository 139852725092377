import { ActionFunctionArgs, DataFunctionArgs, type MetaFunction } from '@remix-run/node'
import { ClientOnly } from "remix-utils/client-only";
import logo from '#app/components/images/logos/main-logo-transparent-bg.svg'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { EmailSchema } from '#app/utils/user-validation.ts'
import { z } from 'zod'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { json, useFetcher, useLoaderData } from '@remix-run/react'
import { ErrorList, Field } from '#app/components/forms.tsx'
import { Button } from '#app/components/ui/button.tsx'
import { useEffect, useState } from 'react'
import { toast } from 'sonner'
import { Card, CardContent} from "#app/components/ui/card.tsx";
import { Dialog, DialogContent } from '#app/components/ui/dialog.tsx'

export const meta: MetaFunction = () => [{ title: 'SavvyIQ' }]


const WaitlistSchema = z.object({
	email: EmailSchema,
})

const waitlistIntent = 'waitlist'

export async function waitlistAction({ request }: ActionFunctionArgs) {
	const formData = await request.formData()

	const submission = await parseWithZod(formData, {
		async: true,
		schema: WaitlistSchema.superRefine(async ({ id }, ctx) => {
			if (!id) {
				ctx.addIssue({
					path: ['id'],
					code: z.ZodIssueCode.custom,
					message: 'API key ID is required',
				})
			}
		}),
	})
	if (submission.status !== 'success') {
		return json(
			{ result: submission.reply() },
			{ status: submission.status === 'error' ? 400 : 200 },
		)
	}

	return json({
		result: submission.reply(),
	})
}

function waitlistForm() {
	const fetcher = useFetcher<typeof waitlistAction>()

	const [form, fields] = useForm({
		id: 'waitlist',
		constraint: getZodConstraint(WaitlistSchema),
		lastResult: fetcher.data?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: WaitlistSchema })
		}
	})

	useEffect(() => {
		if (fetcher.state === 'idle' && fetcher.data?.result?.status === 'success') {
			toast.info("Success!", {
				description: "You've been added to the waitlist. We'll be in touch soon!",
			});
		}
	}, [fetcher.state, fetcher.data]);

	return (
		<section className='pb-14 pt-10 sm:pb-20 sm:pt-10 lg:pb-10 w-full'>
			<div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
				<fetcher.Form method="POST" action="/waitlist" {...getFormProps(form)} className='flex items-top gap-4 flex-col md:flex-row justify-center'>

					<Field
						labelProps={{
							htmlFor: fields.email.id,
							children: '',
						}}
						inputProps={{ ...Object.assign({}, getInputProps(fields.email, { type: 'text' }), { placeholder: 'Email address' }), autoFocus: false, className: 'text-md' }}
						errors={fields.email.errors}
						className='w-100% md:w-56'
					/>
					<ErrorList errors={form.errors} id={form.errorId} />

					<Button className="w-100% md:w-48 bg-[#00ada8] hover:bg-[#00ada8] hover:opacity-75 font-bold text-base"
						type="submit"
						name="intent"
						disabled={fetcher.state !== 'idle'}
						value={waitlistIntent}>Join the Waitlist</Button>

				</fetcher.Form>
			</div>
		</section>
	)
}

function HeaderSide() {
	return (
		<div className='flex justify-center items-center mx-auto w-full'>
			<div className='w-full px-4 sm:px-6 lg:px-0 pt-16 text-center flex flex-col items-center'>
				<div className='w-full px-4 sm:px-6 lg:px-7'>
					<h1 className='font-display text-5xl tracking-tight text-slate-900 sm:text-5xl md:text-7xl font-bold'>Business Identity, <br className='hidden md:block'/> Enrichment, and Insights <br  className='hidden md:block'/> to Power Modern Products</h1>
					<p className='mt-12 font-display text-xl tracking-tight text-slate-900 sm:text-2xl md:text-3xl max-w-[1000px] mx-auto'>Deliver smarter products and eliminate manual work with AI-powered data. Trusted by industry leaders, public enterprises, and growing SMBs alike.</p>
				</div>

				{waitlistForm()}
			</div>
		</div>
	)
}

export default function Index() {

	return (
		<div>
			<header className=" h-10 items-center space-x-1 bg-background inset-x-3 top-4 z-50 mx-auto flex max-w-4xl justify-center overflow-hidden py-12">
				<nav aria-label="Main" data-orientation="horizontal" dir="ltr" className="relative z-10 flex max-w-max flex-1 items-center justify-center">
					<div style={{ position: 'relative' }}>
						<ul data-orientation="horizontal" className="group flex flex-1 list-none items-center justify-center space-x-1" dir="ltr">
							<li>
								<a className="group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium " href="/" data-radix-collection-item="">
									<img src={logo} alt="SavvyIQ" className="h-8 w-auto" />
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</header>

			<main>
				<HeaderSide />

				<section className='pb-10 pt-10 sm:pb-20 sm:pt-10 lg:pb-10' style={{ background: 'linear-gradient(180deg, rgba(255, 241, 217, 0) 0%, rgb(0 173 168 / 14%) 52%, rgba(255, 241, 217, 0) 100%)' }}>
					<div className='mx-auto max-w-[1380px] px-4 sm:px-6 2xl:px-0'>
						<div className="lg:mt-14 lg:block">
							<Card className='shadow-xl'>
								<CardContent className='p-10'>
									<div className="grid sm:grid-cols-1 md:grid-cols-3 gap-8 md:gap-0" role="tablist" aria-orientation="horizontal">
										<div className="relative hover:opacity-100 md:border-r-2 md:pr-5 md:mr-5">
											<div className="w-9 rounded-lg bg-[#00ada8]">
												<svg aria-hidden="true" className="h-9 w-9" fill="none">
													<defs>
														<linearGradient id=":R1bdqfja:" x1="11.5" y1="18" x2="36" y2="15.5" gradientUnits="userSpaceOnUse"><stop offset=".194" stop-color="#fff"></stop><stop offset="1" stop-color="#6692F1"></stop>
														</linearGradient>
													</defs>
													<path d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5" stroke="url(#:R1bdqfja:)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
											</div>
											<h2 className="mt-4 font-display text-3xl text-slate-900 font-bold">
												Turn Business Names into Legal Entities
											</h2>
											<p className="mt-4 text-md text-slate-600"><span className='font-bold'>Entity resolution</span> automatically converts names of companies in unstructured text to legal entities, eliminating manual research and guesswork.</p>
										</div>
										<div className="relative hover:opacity-100">
											<div className="w-9 rounded-lg bg-[#00ada8]">
												<svg aria-hidden="true" className="h-9 w-9" fill="none"><path opacity=".5" d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path><path opacity=".3" d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path><path d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff"></path></svg>
											</div>
											<h2 className="mt-4 font-display text-3xl text-slate-900 font-bold">
												Get Intelligence on <br />Over 250M Global Entities
											</h2>
											<p className="mt-4 text-md text-slate-600"><span className='font-bold'>Real-time enrichment</span> compiles firmographic data from numerous sources into one accurate profile with over 50 data points, mapping intricate business relationships into a single business graph.</p>
										</div>
										<div className="relative hover:opacity-100 md:border-l-2 md:pl-5 md:ml-5">
											<div className="w-9 rounded-lg bg-[#00ada8]">
												<svg aria-hidden="true" className="h-9 w-9" fill="none"><path opacity=".5" d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z" fill="#fff"></path><path d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z" fill="#fff"></path></svg>
											</div>
											<h2 className="mt-4 font-display text-3xl text-slate-900 font-bold">
												Gather Deep Insights from Unstructured Data
											</h2>
											<p className="mt-4 text-md text-slate-600"><span className='font-bold'>Entity-based insights</span> are gathered from a variety of unstructured sources, including news articles, regulatory filings, web content, data feeds, and are automatically mapped back to an entity.</p>
										</div>
									</div>
								</CardContent>
							</Card>

						</div>
					</div>
				</section>

			</main>

			<footer className="">
				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					<div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-end">
						<p className="mt-6 text-sm text-slate-500 sm:mt-0">Copyright © 2025 SavvyIQ, Inc. All rights reserved.</p></div></div>
			</footer>

			{/* <!-- Google tag (gtag.js) --> */}
			<script async src="https://www.googletagmanager.com/gtag/js?id=G-WZBH1HXHNH"></script>
			<script dangerouslySetInnerHTML={{
				__html: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-WZBH1HXHNH');`}}>

			</script>
		</div>
	)
}
